/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { compact, groupBy, isNil, orderBy } from 'lodash'
import { styled, Grid, Icon, Tooltip } from '@mui/material'
import moment from 'moment'
import { TextButton } from '../components/styled/buttons'
import AgriAction from '../agri/actions/AgriAction'
import { formatMilliers } from '../../../utils/StringUtil'
import CmsAction from '../cms/actions/CmsAction'
import { getDate, getMiniDate, getMiniDateNoYear } from '../../../utils/DateUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { darkColor, mainColor, screenColor, veryLightColor } from '../components/styled/theme'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import { getEvolValue } from '../../../utils/AgriUtils'
import { CHRONICLES_CONSTANTS, LOW_WATER_COLOR, NOT_LOW_WATER_COLOR } from '../agri/constants/AgriConstants'
import { AcUnit, Info, LightMode, People, PlayArrow } from '@mui/icons-material'
import LoadingCard from '../components/cards/LoadingCard'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../referencials/materiels/constants/MaterielConstants'
import AccordeonDesktop from '../components/AccordeonDesktop'
import { ReactComponent as PumpLogo } from '../../../ressources/static/svg/Pump.svg'
import { ReactComponent as CounterLogo } from '../../../ressources/static/svg/Counter.svg'
import useApplicationSetting from '../../../utils/customHooks/useApplicationSetting'
import ModalIndexEntry from '../components/ModalIndexEntry'
import { useParams } from 'react-router'

const GridItem = styled(Grid)({
    padding: '4px !important',
    textAlign: 'center',
    alignSelf: 'center',
})

const VolumeItem = styled(Grid)({
    textAlign: 'end',
})

const PointConsoDesktop = () => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [realConso, setRealConso] = useState({ total: undefined, lowWater: undefined, notLowWater: undefined })
    const [year, setYear] = useState(new Date().getFullYear())
    const [openModalNewIndex, setOpenModalNewIndex] = useState(false)
    const [idMatSelected, setIdMatSelected] = useState()
    const [consoType, setConsoType] = useState()

    const dispatch = useDispatch()

    const {
        installation,
        variousMateriels,
        variousMatSituations,
        exploitation,
        exploitationVolumes,
        contributors,
        managementUnits,
    } = useSelector((store) => ({
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        exploitation: store.AgriReducer.exploitation,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        contributors: store.ReferencialReducer.contributors,
        managementUnits: store.ReferencialReducer.managementUnits,
    }), shallowEqual)

    const params = useParams()

    const idInstallation = useMemo(() => Number(params.id), [])
    const date = useMemo(() => moment().valueOf(), [])
    const linkPoint = exploitation.link_samplings.find((l) => l.idInstallation === idInstallation) || {}
    const { pumpsIds, countersIds } = (() => {
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation && (!m.situationEndDate || m.situationEndDate > date) && (!m.situationDate || m.situationDate < date)) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode) && (!m.situationEndDate || m.situationEndDate > moment(`1/01/${year}`, 'DD/MM/YYYY').valueOf()) && (!m.situationDate || m.situationDate < moment(`31/12/${year}`, 'DD/MM/YYYY').valueOf())) || []
        return { pumpsIds: pointPumps.map(({ idVarious }) => idVarious), countersIds: pointCounters.map(({ idVarious }) => idVarious) }
    })()
    const volumes = exploitationVolumes.filter((v) => v.idInstallation === idInstallation && v.askedYear === year)
    const askedVolume = volumes.reduce((acc, v) => acc + (v.askedVolume || 0), 0)
    const attributedVolume = volumes.reduce((acc, v) => acc + (v.attributedVolume || 0), 0)
    const authorizedVolume = volumes.some(v => v.authorizedVolume) ? volumes.reduce((acc, v) => acc + (v.authorizedVolume || 0), 0) : undefined
    const authorizedLowWaterVolume = volumes.some(v => v.authorizedLowWaterVolume) ? volumes.reduce((acc, v) => acc + (v.authorizedLowWaterVolume || 0), 0) : undefined

    const pointVolumes = {
        askedVolume,
        attributedVolume,
        authorizedVolume,
        authorizedLowWaterVolume,
        authorizedNotLowWaterVolume: !isNil(authorizedVolume) && !isNil(authorizedLowWaterVolume) ? authorizedVolume - authorizedLowWaterVolume : undefined,
    }
    const materielsTypeForIndex = useApplicationSetting('materielsTypeForIndex', (setting) => setting ? setting.split(',').map((id) => Number(id)) : [])

    const chroniclesMat = exploitation.link_chronicles.filter(({ idMat }) => [...pumpsIds, ...countersIds].includes(idMat))
    const pointChronicles = chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year &&
        (!linkPoint.startDate || measureDate >= linkPoint.startDate) &&
        (!linkPoint.endDate || measureDate < linkPoint.endDate)
    )

    const pointChroniclePrevYear = orderBy(chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year - 1 &&
        (!linkPoint.startDate || measureDate >= linkPoint.startDate) &&
        (!linkPoint.endDate || measureDate < linkPoint.endDate)
    ), ['measureDate', 'endDate'], 'desc')[0]
    const chroniclesToShow = pointChroniclePrevYear ? [...pointChronicles, pointChroniclePrevYear] : [...pointChronicles]
    const groupedByMat = Object.values(groupBy(chroniclesToShow, 'idMat'))


    useEffect(() => {
        dispatch(AgriAction.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf())).then((res) => {
            setRealConso({
                total: res?.total,
                lowWater: res?.lowWater,
                notLowWater: (!isNil(res?.total) && !isNil(res?.lowWater)) ? res?.total - res?.lowWater : undefined,
            })
        })
    }, [idInstallation, exploitation.idExploitation, exploitation.link_chronicles, year])

    useEffect(() => {
        dispatch(CmsAction.fetchCmsByCategory(5))
        dispatch(ReferencialAction.fetchBookmarks())
        if (!exploitation.idExploitation) {
            dispatch(AgriAction.fetchExploitation()).then(() => setDataLoaded(true))
        } else {
            setDataLoaded(true)
        }
        window.scrollTo(0, 0)
    }, [])

    const ugLowWater = useMemo(() => {
        const managementCode = installation?.subManagementCode ?? installation?.managementCode
        if (!isNil(managementCode)) {
            const managementUnit = managementUnits.find((u) => u.managementCode === managementCode)
            if (managementUnit?.lowWaterStartDate && managementUnit?.lowWaterEndDate) {
                return {
                    startDay: moment(managementUnit.lowWaterStartDate).date(),
                    startMonth: moment(managementUnit.lowWaterStartDate).month(),
                    endDay: moment(managementUnit.lowWaterEndDate).date(),
                    endMonth: moment(managementUnit.lowWaterEndDate).month(),
                }
            }
            return undefined
        }
        return undefined
    }, [managementUnits, installation])

    const getSampledTotal = (chronicles = []) => {
        if (!chronicles.length) return undefined
        if (chronicles.length === 1) return formatMilliers(chronicles[0].value)

        return formatMilliers(chronicles[0].value - chronicles[chronicles.length - 1].value)
    }

    const getColorRealConso = (v1, v2) => {
        if (!hasValue(v1) || !hasValue(v2)) return undefined
        if (v2 > v1) return 'red'
        if (((v2 / v1) * 100) >= 80) return 'orange'
        return 'green'
    }

    const getChroniclesByType = (chroniclesByType, type, readingCoefficient = 1, shared) => {
        if (chroniclesByType.length) {
            const orderedChronicles = orderBy(chroniclesByType, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
            const currentChronicles = orderedChronicles.filter((c) => moment(c.measureDate).year() >= year).map((c) => {
                const day = moment(c.measureDate).date()
                const month = moment(c.measureDate).month()
                const isLowWater = day >= ugLowWater?.startDay && month >= ugLowWater?.startMonth && day <= ugLowWater?.endDay && month <= ugLowWater?.endMonth
                return { ...c, isLowWater }
            })
            const pastChronicles = orderedChronicles.filter((c) => moment(c.measureDate).year() < year)
            const clickable = shared ? '' : 'clickable'
            const props = {
                onClick: shared ? () => {} : () => {
                    setOpenModalNewIndex(true)
                    setIdMatSelected(chroniclesByType[0].idMat)
                    setConsoType(type)
                }
            }
            return {
                nbLines: currentChronicles.length,
                total: getSampledTotal(currentChronicles),
                content: (
                    <>
                        <GridItem item className={`bold ${clickable}`} xs={3} {...props} sx={{ textAlign: 'start' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                        </GridItem>
                        <GridItem item className={`bold ${clickable}`} xs={3} {...props} sx={{ textAlign: 'start' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.endDate : ''}
                        </GridItem>
                        <GridItem item className={`bold ${clickable}`} xs={3} {...props} sx={{ textAlign: 'end' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.estimateM3 : i18n.index}
                        </GridItem>
                        <GridItem item className={`bold ${clickable}`} xs={3} sx={{ textAlign: 'end' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.accumulationM3 : i18n.evolutionM3}
                        </GridItem>
                        <Grid item xs={12} sx={{ borderBottom: `1px solid ${mainColor}`, margin: '10px 0' }} />
                        {currentChronicles.map((chronicle, i) => {
                            const valueEvol = i !== currentChronicles.length - 1 ? getEvolValue(type, currentChronicles, chronicle, i) : null
                            const className = i === 0 ? `bold ${clickable}` : clickable
                            const isLastNotLowWater = !chronicle.isLowWater && i === 0
                            const isLastLowWater = chronicle.isLowWater && !currentChronicles.some((c) => c.isLowWater && c.measureDate > chronicle.measureDate && c.idChronicle != chronicle.idChronicle)
                            const isFirstLowWater = chronicle.isLowWater
                                && !currentChronicles.some((c, j) => c.isLowWater && c.measureDate <= chronicle.measureDate && c.idChronicle != chronicle.idChronicle && i < j)
                                && currentChronicles.some((c, j) => !c.isLowWater && c.measureDate <= chronicle.measureDate && c.idChronicle != chronicle.idChronicle && i < j)
                            return (
                                <>
                                    {ugLowWater && isLastNotLowWater && (
                                        <Grid
                                            item
                                            className='bold'
                                            sx={{
                                                margin: '10px 0',
                                                padding: '5px 15px',
                                                backgroundColor: NOT_LOW_WATER_COLOR,
                                                borderRadius: '8px',
                                                border: `1px solid ${darkColor}`,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <AcUnit sx={{ marginRight: '10px' }} />
                                            {i18n.horsEtiage}
                                        </Grid>
                                    )}
                                    {ugLowWater && isLastLowWater && (
                                        <Grid
                                            item
                                            className='bold'
                                            sx={{
                                                margin: '10px 0',
                                                padding: '5px 15px',
                                                backgroundColor: LOW_WATER_COLOR,
                                                borderRadius: '8px',
                                                border: `1px solid ${darkColor}`,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <LightMode sx={{ marginRight: '10px' }} />
                                            {i18n.lowWater}
                                        </Grid>
                                    )}
                                    <Grid item xs={12} container className={className} {...props} sx={{ padding: '5px', borderRadius: '8px', backgroundColor: (i % 2 || !ugLowWater) ? '' : (chronicle.isLowWater ? LOW_WATER_COLOR : NOT_LOW_WATER_COLOR) }}>
                                        <GridItem item xs={3} sx={{ textAlign: 'start' }}>
                                            {moment(chronicle.measureDate).year() === year ? getMiniDateNoYear(chronicle.measureDate) : getMiniDate(chronicle.measureDate)}
                                        </GridItem>
                                        <GridItem item xs={3} sx={{ textAlign: 'start' }}>
                                            {chronicle.endDate ? (moment(chronicle.endDate).year() === year ? getMiniDateNoYear(chronicle.endDate) : getMiniDate(chronicle.endDate)) : ''}
                                        </GridItem>
                                        <GridItem item xs={3} sx={{ textAlign: 'end' }}>
                                            {formatMilliers(chronicle.value) || 0}
                                        </GridItem>
                                        <GridItem item xs={3} sx={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                            {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0))}` : ''}
                                        </GridItem>
                                    </Grid>
                                    {ugLowWater && isFirstLowWater && (
                                        <Grid
                                            item
                                            className='bold'
                                            sx={{
                                                margin: '10px 0',
                                                padding: '5px 15px',
                                                backgroundColor: NOT_LOW_WATER_COLOR,
                                                borderRadius: '8px',
                                                border: `1px solid ${darkColor}`,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <AcUnit sx={{ marginRight: '10px' }} />
                                            {i18n.horsEtiage}
                                        </Grid>
                                    )}
                                </>
                            )
                        })}
                        {pastChronicles.length > 0 && <GridItem item xs={12}>...</GridItem>}
                        {pastChronicles.map((chronicle) => (
                            <>
                                <GridItem item xs={3} {...props} sx={{ textAlign: 'start' }}>
                                    {getDate(chronicle.measureDate)}
                                </GridItem>
                                <GridItem item xs={3} {...props} sx={{ textAlign: 'start' }}>
                                    {chronicle.endDate ? getDate(chronicle.endDate) : ''}
                                </GridItem>
                                <GridItem item xs={3} {...props} sx={{ textAlign: 'end' }}>
                                    {`${formatMilliers(chronicle.value) || 0}${type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? 'm3' : ''}`}
                                </GridItem>
                                <GridItem item xs={3}>
                                    {moment(chronicle.measureDate).year() !== year && <Icon>access_time</Icon>}
                                </GridItem>
                            </>
                        ))}
                    </>
                )
            }
        }
        return { nbLines: 0, content: null }
    }

    const getChronicles = (groupedChronicles) => {
        return compact(groupedChronicles.map((chronicles) => {
            const variousMat = variousMateriels.find((mat) => mat.id === chronicles[0].idMat) || {}
            if (!hasValue(variousMat.administrator) || (variousMat.administrator === exploitation.operatorCode) || variousMat.isShared) {
                const shared = hasValue(variousMat.administrator) && (variousMat.administrator !== exploitation.operatorCode) && variousMat.isShared
                const indexChronicles = chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX)
                const estimateChronicles = chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM)
                const readingCoefficient = variousMat?.counter?.readingCoefficient || 1
                const indexs = getChroniclesByType(indexChronicles, CHRONICLES_CONSTANTS.TYPE_INDEX, readingCoefficient, shared)
                const estimates = getChroniclesByType(estimateChronicles, CHRONICLES_CONSTANTS.TYPE_ESTIM, 1, shared)
                const isNotEstim = materielsTypeForIndex.includes(variousMat.materielType)
                return {
                    nbLines: indexs.nbLines + estimates.nbLines,
                    title: (
                        <Grid
                            container
                            flexWrap='nowrap'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Grid container item alignItems='center'>
                                {variousMat.pump ? <PumpLogo style={{ paddingRight: 10 }}/> : <CounterLogo style={{ paddingRight: 10 }}/>}
                                {`
                                    ${variousMat.pump ? i18n.pump: i18n.counter}
                                    ${variousMat.reference}
                                    ${variousMat?.counter?.informative ? ` (${i18n.informative})` : ''}
                                `}
                                {shared && (
                                    <Tooltip title={`${i18n.sharedEquipment} (${contributors.find((c) => c.id === variousMat.administrator)?.name})`}>
                                        <People sx={{ marginLeft: '8px' }}/>
                                    </Tooltip>
                                )}
                            </Grid>
                            <Grid item container alignItems='center' justifyContent='flex-end' sx={{ paddingRight: '3vw' }}>
                                {i18n.total} : {isNotEstim && indexs.total ? `${indexs.total} ${i18n.m3}` : `${estimates.total ?? '-'} ${estimates.total ? i18n.m3 : ''}`}
                            </Grid>
                        </Grid>
                    ),
                    content: (
                        <Grid
                            item
                            container
                            direction='row'
                            justifyContent='flex-start'
                        >
                            {indexs.content}
                            {estimates.content}
                        </Grid>
                    )
                }
            }
            return null
        }))
    }
    const chroniclesValues = getChronicles(groupedByMat)

    const estimConso = useMemo(() => {
        if (groupedByMat.length &&
            groupedByMat.some((chroniques) => chroniques.some((c) => moment(c.measureDate).year() >= year &&
                c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM))) {
            return groupedByMat.map((chroniques) => {
                return chroniques.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM &&
                    moment(c.measureDate).year() >= year).reduce((acc, c) => acc + c.value, 0) || 0
            }).reduce((acc, c) => acc + c, 0)
        }
        return undefined
    }, [groupedByMat, year])

    const onChangeYear = (yearToFetch) => {
        dispatch(AgriAction.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${yearToFetch}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${yearToFetch}`, 'DD/MM/YYYY').valueOf())).then((res) => {
            setRealConso(res?.total)
            setYear(yearToFetch)
        })
    }

    const formatVolumes = (volume) => {
        return !isNil(volume) ? `${formatMilliers(volume)} m3` : i18n.unknown
    }

    if (variousMatSituations.length && variousMateriels.length && dataLoaded && exploitation.idExploitation) {
        return (
            <Grid container alignItems='center' justifyContent='space-around' sx={{ padding: '8px 1.5rem' }}>
                <Grid container item rowGap='16px' sx={{ backgroundColor: screenColor, borderRadius: '12px', padding: '4vh' }}>
                    <Grid
                        item
                        container
                        className='bold'
                        alignItems='center'
                        justifyContent='center'
                        sx={{
                            fontSize: '16px',
                            lineHeight: '24px'
                        }}
                    >
                        {i18n.campaign}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{ borderBottom: `1px solid ${mainColor}` }}
                    >
                        <Grid item>
                            <TextButton
                                sx={{ margin: 0 }}
                                onClick={() => onChangeYear(year - 1)}
                                startIcon={<PlayArrow sx={{ rotate: '180deg' }} />}
                            >
                                {year - 1}
                            </TextButton>
                        </Grid>
                        <Grid item>
                            <b style={{ fontSize: '32px', fontWeight: 400 }}>{year}</b>
                        </Grid>
                        <Grid item>
                            <TextButton
                                onClick={() => {
                                    onChangeYear(year + 1)
                                }}
                                className={year === new Date().getFullYear() ? 'hide' : ''}
                                endIcon={<PlayArrow />}
                            >
                                {year + 1}
                            </TextButton>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        sx={{ padding: '0 10 0 10', color: mainColor }}
                    >
                        <Grid
                            item
                            xs={12}
                            container
                            direction='column'
                            alignItems='center'
                        >
                            <Grid item container justifyContent='flex-end' sx={{ padding: '15px 0', fontStyle: 'italic' }}>
                                <VolumeItem item xs={2.5}>{i18n.yearly}</VolumeItem>
                                <VolumeItem item xs={2.5}>{i18n.lowWater}</VolumeItem>
                                <VolumeItem item xs={2.5}>{i18n.horsEtiage}</VolumeItem>
                            </Grid>
                            <Grid item container justifyContent='space-between' sx={{ padding: '15px 0' }}>
                                <Grid item xs={4.5}>
                                    {i18n.authorizedVolume}
                                </Grid>
                                <VolumeItem item xs={2.5}>
                                    {formatVolumes(pointVolumes.authorizedVolume)}
                                </VolumeItem>
                                <VolumeItem item xs={2.5}>
                                    {formatVolumes(pointVolumes.authorizedLowWaterVolume)}
                                </VolumeItem>
                                <VolumeItem item xs={2.5}>
                                    {formatVolumes(pointVolumes.authorizedNotLowWaterVolume)}
                                </VolumeItem>
                            </Grid>
                            <Grid item container justifyContent='space-between' sx={{ padding: '15px 0', backgroundColor: veryLightColor }}>
                                <Grid item xs={4.5} container alignItems='center'>
                                    {i18n.estimConsumption}
                                    <Tooltip title={i18n.estimConsumptionDescr}>
                                    <Info fontSize='small' sx={{ marginLeft: '5px' }} />
                                    </Tooltip>
                                </Grid>
                                <VolumeItem item xs={2.5}>
                                    {hasValue(estimConso) ? `${formatMilliers(estimConso)} ${i18n.m3}` : '-'}
                                </VolumeItem>
                                <VolumeItem item xs={2.5}>-</VolumeItem>
                                <VolumeItem item xs={2.5}>-</VolumeItem>
                            </Grid>
                            <Grid item container justifyContent='space-between' sx={{ padding: '15px 0' }}>
                                <Grid item xs={4.5} container alignItems='center'>
                                    {i18n.realConsumption}
                                    <Tooltip title={i18n.realConsumptionDescr}>
                                        <Info fontSize='small' sx={{ marginLeft: '5px' }} />
                                    </Tooltip>
                                </Grid>
                                <VolumeItem item xs={2.5} sx={{ color: getColorRealConso(pointVolumes?.authorizedVolume, realConso?.total) }}>
                                    {formatVolumes(realConso?.total)}
                                </VolumeItem>
                                <VolumeItem item xs={2.5} sx={{ color: getColorRealConso(pointVolumes?.authorizedLowWaterVolume, realConso?.lowWater) }}>
                                    {formatVolumes(realConso?.lowWater)}
                                </VolumeItem>
                                <VolumeItem item xs={2.5} sx={{ color: getColorRealConso(pointVolumes?.authorizedNotLowWaterVolume, realConso?.notLowWater) }}>
                                    {formatVolumes(realConso?.notLowWater)}
                                </VolumeItem>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container >
                    {chroniclesValues.map((c) => (
                        <AccordeonDesktop key={c.title} title={c.title} item xs={12} sx={{ width: '100%' }}>
                            {c.content}
                        </AccordeonDesktop>
                    ))}
                </Grid>
                {openModalNewIndex && <ModalIndexEntry
                    point={{ ...installation, consoType }}
                    openModalNewIndex={openModalNewIndex}
                    setOpenModalNewIndex={setOpenModalNewIndex}
                    material={variousMateriels.find(m => m.id === idMatSelected)}
                />}
            </Grid>
        )
    }
    return <LoadingCard />
}

PointConsoDesktop.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
    sx: PropTypes.shape({}),
}

export default PointConsoDesktop